import React from "react";
import { AppBar, Toolbar, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import "./Navbar.css";

const useStyles = makeStyles({
  toolbar: {
    justifyContent: "center",
    backgroundColor: "transparent",
  },
});

const Navbar = () => {
  const classes = useStyles();

  return (
    <div>
      <Toolbar className={classes.toolbar}>
        <Typography variant="h4" align="center">
          <Link to="/" className="navbar-logo">
            App Store
          </Link>
        </Typography>
      </Toolbar>
    </div>
  );
};

export default Navbar;
