import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Container,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import { db } from "../firebase2";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
const functions = getFunctions();

const Home = () => {
  const [macAddress, setMacAddress] = useState("");
  const [email, setEmail] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [error, setError] = useState("");
  const [checkedMac, setCheckedMac] = useState(false);
  const [isLGDevice, setIsLGDevice] = useState(false);
  const [agreedToTerms, setAgreedToTerms] = useState(false);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleTermsChange = (event) => {
    setAgreedToTerms(event.target.checked);
  };

  const openTermsOfService = () => {
    window.open("https://isi-appstore.de/terms-and-conditions", "_blank");
  };

  const handleMacAddressChange = (event) => {
    const input = event.target.value;
    // Allow only valid characters in a MAC address
    const sanitizedInput = input.replace(/[^a-fA-F0-9]/g, "");
    // Add colons automatically
    const formattedInput = sanitizedInput
      .substring(0, 12) // Limit input to a maximum of 12 characters
      .match(/.{1,2}/g);
    if (formattedInput) {
      // Check if formattedInput is not null
      const macAddress = formattedInput.join(":").toUpperCase();
      setMacAddress(macAddress);
    } else {
      setMacAddress("");
    }
  };

  const handleCheckboxChange = (event) => {
    setMacAddress("");
    setIsLGDevice(event.target.checked);
  };

  const handleLGAddressChange = (event) => {
    const macAddress = event.target.value;

    if (macAddress) {
      setMacAddress(macAddress.toLowerCase());
    } else {
      setMacAddress("");
    }
  };

  const handleEmailChange = (event) => {
    const input = event.target.value;
    setEmail(input);
  };

  const isMacInDb = async (macAddress) => {
    const docRef = doc(db, "customers", macAddress);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return true;
    } else {
      return false;
    }
  };

  const handleActivate = async () => {
    if (!macAddress || !email) {
      setError("Please enter a valid MAC address and email.");
      setOpenModal(true);
    } else if (!isValidMacAddress(macAddress) && !isLGDevice) {
      setError("Please enter a valid MAC address.");
      setOpenModal(true);
    } else if (!macAddress && isLGDevice) {
      setError("Please enter a valid ID.");
      setOpenModal(true);
    } else if (!isValidEmail(email)) {
      setError("Please enter a valid email address.");
      setOpenModal(true);
    } else {
      const macInDb = await isMacInDb(macAddress);
      if (!macInDb) {
        setError("MAC address not found. Please open the app and try again.");
        setOpenModal(true);
      } else {
        setError("");

        setCheckedMac(true);
      }
    }
  };

  const isValidMacAddress = (macAddress) => {
    const macAddressRegex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
    return macAddressRegex.test(macAddress);
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return emailRegex.test(email);
  };

  const isActivateDisabled = !macAddress;

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const macAddress = urlParams.get("mac");
    const lgDevice = urlParams.get("lg") || "false";
    if (macAddress && lgDevice == "false") {
      handleMacAddressChange({ target: { value: macAddress } });
    }
    if (lgDevice && lgDevice === "true") {
      setIsLGDevice(true);
      handleLGAddressChange({ target: { value: macAddress } });
    }
  }, []);

  return (
    <Container className="activation-container" maxWidth="sm">
      <Typography variant="h4" component="h1" gutterBottom>
        Royal App
      </Typography>

      {checkedMac ? (
        <>
          <form
            action="https://us-central1-royaltvapp-bc006.cloudfunctions.net/createCheckoutSession"
            method="POST"
          >
            <input
              type="hidden"
              name="priceId"
              value={"price_1NslIpHvPEynhZ4JF4vI86Qr"}
            />
            <input type="hidden" name="email" value={email ? email : ""} />
            <input
              type="hidden"
              name="macAddress"
              value={macAddress ? macAddress : ""}
            />
            <div>Email: {email}</div>
            <div>Mac: {macAddress}</div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={agreedToTerms}
                  onChange={handleTermsChange}
                  color="primary"
                />
              }
              label={
                <span>
                  I agree to the
                  <Button color="primary" onClick={openTermsOfService}>
                    Terms and Conditions
                  </Button>
                </span>
              }
            />
          </form>
          <br />
          <br />
          <form
            action="https://us-central1-royaltvapp-bc006.cloudfunctions.net/createCheckoutSession2"
            method="POST"
          >
            <input
              type="hidden"
              name="priceId"
              value={"price_1NZv4GHvPEynhZ4JLYMnE4fK"}
            />
            <input type="hidden" name="email" value={email ? email : ""} />
            <input
              type="hidden"
              name="macAddress"
              value={macAddress ? macAddress : ""}
            />

            <Button
              id="checkout-and-portal-button"
              type="submit"
              size="large"
              color="primary"
              fullWidth
              variant="contained"
              disabled={!agreedToTerms}
            >
              Purchase Premium - One-time Fee €19.99 (Lifetime)
            </Button>
          </form>
        </>
      ) : (
        <form className="activation-form" onSubmit={(e) => e.preventDefault()}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isLGDevice}
                onChange={handleCheckboxChange}
                color="primary"
              />
            }
            label="I am using an LG or Android TV"
          />
          {!isLGDevice ? (
            <TextField
              label="MAC Address"
              fullWidth
              margin="normal"
              value={macAddress}
              onChange={handleMacAddressChange}
            />
          ) : (
            <>
              <div>
                <Typography
                  variant="subtitle1"
                  color="error"
                  style={{ fontWeight: "bold" }}
                >
                  Please enter the ID of your device. Not your MAC address! You
                  can find it in the app under "Settings" or on the Starting
                  Screen.
                </Typography>
              </div>
              <TextField
                label={isLGDevice ? "LG ID or Android ID" : "Check MAC"}
                fullWidth
                margin="normal"
                value={macAddress}
                onChange={handleLGAddressChange}
              />
            </>
          )}
          <TextField
            label="Email"
            type="email"
            fullWidth
            margin="normal"
            value={email}
            onChange={handleEmailChange}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              className="activation-button"
              variant="contained"
              color="primary"
              onClick={handleActivate}
            >
              {isLGDevice ? "Check ID" : "Check MAC"}
            </Button>
            <Button
              className="activation-button"
              variant="contained"
              color="primary"
              onClick={() => {
                window.open(
                  "https://billing.stripe.com/p/login/28obKtfIw2Z31IAaEE",
                  "_blank"
                );
              }}
            >
              Manage Subscription
            </Button>
          </div>
        </form>
      )}

      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <DialogContentText>{error}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Home;
