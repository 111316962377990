import React from "react";
import Footer from "./Footer";
import "./Layout.css";
import Navbar from "./Navbar";

const Layout = ({ children }) => {
  return (
    <div className="layout">
      <Navbar />

      <div className="content">{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
