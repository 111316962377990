import React from "react";
import { Container, Box, Typography } from "@mui/material";

const TermsAndConditions = () => {
  return (
    <Container maxWidth="md">
      <Box mt={4} mb={2}>
        <Typography variant="h4" align="center">
          Terms and Conditions
        </Typography>
      </Box>
      <Typography variant="body1" paragraph>
        <Typography variant="h6">1. Scope</Typography>
        <p>
          These Terms and Conditions apply to all users of the Royal App
          service, provided by Isidev, hereinafter referred to as the "Service".
        </p>

        <Typography variant="h6">2. Service Description</Typography>
        <p>
          The Service offers video streaming and playback functionalities.
          Features may include, but are not limited to, video playback, playlist
          management, and video quality settings.
        </p>

        <Typography variant="h6">3. User Registration and Account</Typography>
        <p>
          Users must register with the MAC Address of the device for an account
          to use the Service. Account holders are responsible for maintaining
          the confidentiality of their account information and for all
          activities under their account.
        </p>

        <Typography variant="h6">4. Fees and Payment</Typography>
        <p>
          Certain features of the Service may require payment. All fees are
          displayed during the registration or purchase process and must be paid
          in advance. The Service operates on a no-refund policy.
        </p>

        <Typography variant="h6">5. Usage Rights and Restrictions</Typography>
        <p>
          Users are granted a non-exclusive, non-transferable right to access
          and use the Service. The Service must not be used for any unlawful
          activities or in violation of any laws or third-party rights.
        </p>

        <Typography variant="h6">6. Termination</Typography>
        <p>
          Isidev reserves the right to terminate or suspend access to the
          Service for any user who violates these Terms and Conditions or for
          non-payment of fees.
        </p>

        <Typography variant="h6">7. No Refunds</Typography>
        <p>
          All sales and transactions are final. The Service does not provide
          refunds or credits for any purchases, except as required by law.
        </p>

        <Typography variant="h6">
          8. Disclaimer of Warranties and Limitation of Liability
        </Typography>
        <p>
          The Service is provided "as is" without any warranties. Isidev is not
          liable for any direct, indirect, incidental, or consequential damages
          arising from the use or inability to use the Service.
        </p>

        <Typography variant="h6">
          9. Amendments to Terms and Conditions
        </Typography>
        <p>
          Isidev reserves the right to modify these Terms and Conditions at any
          time. Continued use of the Service after any changes constitutes
          acceptance of the new Terms.
        </p>

        <Typography variant="h6">10. Governing Law</Typography>
        <p>
          These Terms and Conditions are governed by the laws of Germany. Any
          disputes related to the Service will be subject to the exclusive
          jurisdiction of the courts in Germany.
        </p>
      </Typography>
      {/* Add more content related to your Legal Terms and Conditions here */}
    </Container>
  );
};

export default TermsAndConditions;
